<template>

<app-page theme="white" :back-button="(filter) ? false : 'Recommendation'" :close-button="(filter) ? 'Recommendation.Survey': false">

	<app-title :text="content.title" :is-dark="true" :is-uppercase="true" is-restricted="true" />

	<app-content class="compare-content">

		<template v-for="(item, index) in content.items">
			
			<template v-if="$_.contains(item.filter, filter) || !filter">

				<app-expand v-if="item.type === 'compare'" :key="index" :title="item.title" :is-active="option === index" :is-locked="filter || window.is.mobile" v-on:click="onOptionClick(index)" class="compare-expand">

					<com-compare :data="item.items" />

				</app-expand>

				<div v-if="item.type === 'text'" :key="index" v-html="item.text" class="compare-text" :class="{'is-last': index === content.items.length - 1}" />

			</template>

		</template>

		<com-foot />

		<app-button v-if="!filter" text="Complete" theme="dark" v-on:click="onCompleteClick" class="compare-button" />

	</app-content>

</app-page>

</template>

<script>

export default {

	components: {
		'com-compare': () => import('./recommendation-compare/Compare'),
		'com-foot': () => import('./recommendation/Foot')
	},

	data: function() {

		return {
			option: 0
		}

	},

	computed: {

		filter: function() {

			return this.$route.params.filter

		},

		content: function() {

			return this.$store.getters['data'].info.compare

		}

	},

	methods: {

		onOptionClick: function(value) {

			this.option = (this.option === value) ? false : value

		},

		onCompleteClick: function() {

			this.$store.commit('completed', 'compare')

			this.$router.push({
				name: 'Recommendation'
			})

		}

	}

}

</script>

<style scoped>

.compare-text {
	padding: 40px 0px;
	border-bottom: 1px solid #707070;
}

.is-mobile .compare-text {
	padding: 20px 0px;
}

.is-mobile .compare-content >>> .content-inner {
	padding-top: 0px;
}

.compare-text.is-last {
	border-bottom-width: 0px;
}

.compare-button {
	margin-top: 80px;
}

.is-mobile .compare-button {
	margin-top: 40px;
}

.is-mobile .compare-expand >>> .expand-title {
	text-align: center;
	padding: 20px 0px;
}

</style>
